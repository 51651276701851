nav {
    background: #F5F5F5;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
    display: flex;
    justify-content: left;
}

.nav-links {
    list-style: none;
    padding: 0 24%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.right-links {
    display: flex;
}

.nav-links li a {
    padding: 10px 15px;
    text-decoration: none;
    color: #333333;
    font-family: Arial, sans-serif;
    font-size: 16px;
    transition: color 0.3s ease;
}

.nav-links li a:hover,
.nav-links li a.active {
    color: #0077cc;
    font-weight: bold;
}

@media (max-width: 768px) {
    .nav-links {
        padding: 0 10px;
        flex-direction: column;
        align-items: flex-start;
    }
    .right-links {
        flex-direction: column;
    }
}
