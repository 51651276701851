.homePage {
    text-align: left;
    padding: 20px;
}

body {
    background-color: #FAF9F6;
}

.intro {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 22px;
}


.typewriter-container {
    width: 400px;
}

.profile-pic {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
}

.about {
    margin-top: 50px;
    margin-bottom: 40px;
    color: #666;
    font-size: 16px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 768px) {
    .intro, nav {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .content-align {
        padding-left: 10px;
        padding-right: 10px;
    }
    .about, .header {
        margin-top: 20px;
    }
}

@media (max-width: 600px) {
    .header {
        margin-bottom: 20px;
    }
}
