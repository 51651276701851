.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 20px;
    background-color: #f8f9fa;
}

.footer a {
    display: inline-block;
}

.footer img {
    width: 30px;
    height: auto;
    transition: transform 0.3s ease;
    border-radius: 50%;
}

.footer img:hover {
    filter: invert(31%) sepia(82%) saturate(653%) hue-rotate(180deg) brightness(111%) contrast(88%);
}
