.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
}

.project-card {
    display: flex;
    align-items: center;
    margin: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    width: 90%;
    max-width: 600px;
}

.project-image img {
    width: 150px;
    height: auto;
    margin-right: 20px;
}

.project-info {
    flex: 1;
    padding: 10px;
}

h3, p {
    margin: 5px 0;
}

@media (min-width: 768px) {
    .project-card {
        flex-direction: row;
        width: 90%;
    }

    .project-image img {
        width: auto;
        max-width: 150px;
        margin-right: 20px;
        margin-bottom: 0;
    }

    .project-info {
        text-align: left;
    }
}